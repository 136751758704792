import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Uninterrupted from "../images/logo.uninterrupted.dark.svg"
import Tsn from "../images/logo.tsnwhite.svg"
import Crave from "../images/logo.cravewhite.svg"
import Scotiabank from "../images/logo.scotiabank.svg"

const Home = ({ data }) => {
    let global = data.contentfulWebsite;
    
    return (
        <>
            <Seo splash={true} metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-container c-splash_wrap">
                <div className="u-text-center o-content-container">
                    <img className="c-logo--large" src={Uninterrupted} alt={global.title} />
                    <h1 className="h1--splash">{global.splashTitle}</h1>
                    <h2 className="h2--splash">{global.splashSubtitle}</h2>
                    {global.splashCTAText !== " " ? <Link to="/home" className="c-button c-button--white c-button--inline">{global.splashCtaText}</Link> : ""}
                    <div className="o-child-spacer o-child-spacer--divider u-flex-center u-flex-wrap">
                        <Link className="c-logo c-logo--large" to="https://www.tsn.ca/" target="_blank" rel="noreferrer">
                            <img src={Tsn} alt="TSN" />
                        </Link>
                        <Link className="c-logo c-logo--large" to="https://www.crave.ca/" target="_blank" rel="noreferrer">
                            <img src={Crave} alt="Crave" />
                        </Link>
                    </div>
                    <h2>Presented by</h2>
                    <div className="o-child-spacer u-flex-center u-flex-wrap">
                        <Link className="c-logo c-logo--xlarge" to="https://www.scotiabank.com/ca/en/personal.html" target="_blank" rel="noreferrer">
                            <img src={Scotiabank} alt="Scotiabank" />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        splashTitle
        splashSubtitle
        splashCtaText
        shareImage {
            file {
                url
            }
        }
    }
}
`;
